<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="fiscal_year_id" vid="fiscal_year_id" rules="required|min_value:1">
              <b-form-group
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.fiscal_year_id"
                :options="fiscalYearList"
                id="clone_info_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="area_type_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.area_type_id"
                      id="area_type_id"
                      :options="getAreaTypeList"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="ItemShow">
            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="division_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.division_id"
                      id="division_id"
                      :options="divisionList"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="ItemShow">
            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="district_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.district_id"
                      id="district_id"
                      :options="districtList"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="PauroshobaItemShow || UnionItemShow">
            <ValidationProvider name="Upazila" vid="upazila_id" v-slot="{ errors }">
                <b-form-group
                    label-for="upazila_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.upazila') }}
                    </template>
                    <b-form-select
                      plain
                      :disabled="isGardenAdmin"
                      v-model="data.upazila_id"
                      id="upazila_id"
                      :options="upazilaList"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-show="CityCorpItemShow">
            <ValidationProvider name="City Corporation" vid="city_corporation_id" v-slot="{ errors }">
              <b-form-group
                label-for="city_corporation_id">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.city_corporation') }}
                </template>
                <b-form-select
                  plain
                  :disabled="isGardenAdmin"
                  v-model="data.city_corporation_id"
                  id="city_corporation_id"
                  :options="cityCorporationList"
                  :state="errors[0] ? false : (valid ? true : null)">
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
                <b-form-group slot-scope="{ valid, errors }" label-for="garden_id">
                    <template v-slot:label>
                      {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="data.garden_id"
                      :options="gardenList"
                      id="garden_id"
                      :state="errors[0] ? false : (valid ? true : null)">
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Date" vid="stock_in_date" rules="required">
              <b-form-group
                class="row"
                label-for="stock_in_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="stock_in_date"
                  v-model="data.stock_in_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- stuff_percentage -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Staff Percentage" vid="stock_in_quantity" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="stock_in_quantity">
                <template v-slot:label>
                  {{ $t('teaGarden.stock_in_quantity') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="stock_in_quantity"
                  type="number"
                  step="0.01"
                  min="0.00"
                  v-model="data.stock_in_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="button" @click="saveUpdate(1)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
            <b-button type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { gardenStockInStore, gardenStockInUpdate, exGardenData } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  data () {
    return {
      ItemShow: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      CityCorpItemShow: false,
      PauroshobaItemShow: false,
      UnionItemShow: false,
      processShow: true,
      processShowR: false,
      roundShow: true,
      roundShowR: false,
      valid: null,
      conditionStatus: '',
      fileRequired: 'required',
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      office_id: 0,
      data: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        round: 2,
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        city_corporation_id: 0,
        garden_id: 0,
        stock_in_date: '',
        status: 1,
        stock_in_quantity: ''
      },
      gardenList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      cityCorporationList: [],
      municipalityList: [],
      maxAppQty: 0
    }
  },
  created () {
    if (this.id) {
      const tmp = this.gdnStockInList()
      this.data = tmp
      const filterData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.data.garden_id)
      if (filterData) {
        this.data.area_type_id = filterData.area_type_id ? filterData.area_type_id : 0
        this.data.division_id = filterData.division_id ? filterData.division_id : 0
        this.data.district_id = filterData.district_id ? filterData.district_id : 0
        this.data.upazila_id = filterData.upazila_id ? filterData.upazila_id : 0
        this.data.city_corporation_id = filterData.city_corporation_id ? filterData.city_corporation_id : 0
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    unitList () {
      return this.$store.state.TeaGardenService.commonObj.unitList
    },
    divisionList () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    getAreaTypeList: function () {
      const objectData = this.$store.state.commonObj.areaTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    applicationPhaseList: function () {
      return this.$store.state.TeaGardenService.commonObj.applicationPhaseList
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    yearList () {
      return helpers.getYearList()
    },
    currentLocale () {
        return this.$i18n.locale
    }
  },
  watch: {
    'data.area_type_id': function (newVal, oldVal) {
      this.gardenList = this.getAreaTypeWiseGardenList(newVal)
      this.ItemShow = true
      if (newVal === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (newVal === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (newVal === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    'data.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
      this.gardenList = this.getDivisionWiseGardenList(newVal)
    },
    'data.district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
      this.gardenList = this.getDistrictWiseGardenList(newVal)
    },
    'data.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.municipalityList = this.getPauroshobaList(newVal)
      this.gardenList = this.getUpazilaWiseGardenList(newVal)
    },
    'data.city_corporation_id': function (newVal, oldVal) {
      this.gardenList = this.getCityCorporationWiseGardenList(newVal)
    }
  },
  methods: {
    async getGardenData () {
      const params = {
        application_year: this.data.application_year,
        garden_id: this.data.garden_id
      }
      const result = await RestApi.getData(teaGardenServiceBaseUrl, exGardenData, params)
      if (result.success) {
        this.data.prev_verified_qty = result.data.total_info.verified_qty
        this.data.round = result.data.total_info.total_app + 1
        if (result.data.total_info.total_app === 3) {
          this.roundShow = false
          this.roundShowR = true
        }
        if (result.data.latest_app_status && result.data.latest_app_status <= 5) {
          this.processShow = false
          this.processShowR = true
        } else {
          this.processShow = true
          this.processShowR = false
        }
      }
    },
    getAreaTypeData (typeId) {
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaList (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionList (upazilaId) {
       return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === districtId)
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    getPauroshobaList (upazillaId = null) {
      const objectData = this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.upazila_id === upazillaId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getAreaTypeWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.area_type_id === id)
    },
    getDivisionWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.division_id === id)
    },
    getDistrictWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.district_id === id)
    },
    getUpazilaWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.upazila_id === id)
    },
    getCityCorporationWiseGardenList (id) {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.city_corporation_id === id)
    },
    async saveUpdate (status = 0) {
      var check = await this.$refs.form.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.data.status = status
        if (this.id) {
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${gardenStockInUpdate}/${this.id}`, this.data)
        } else {
            result = await RestApi.postData(teaGardenServiceBaseUrl, gardenStockInStore, this.data)
        }
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-form')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    gdnStockInList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
